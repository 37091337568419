var delay_tab = 300,
  delay_show_mm = 300,
  delay_hide_mm = 300

$('body').append(getFullscreenBg())

$.fn.initMM = function() {
  var mmpanel = {
    $mobilemenu: $('.panel-menu'),
    mm_close_button: 'Close',
    mm_back_button: 'Back',
    mm_breakpoint: 768,
    mm_enable_breakpoint: false,
    mm_mobile_button: false,
    remember_state: false,
    second_button: false, // class
    init: function($button, data){
      var _this = this
      if(!_this.$mobilemenu.length){
        console.log('You not have <nav class="panel-menu mobile-main-menu">menu</nav>. See Documentation')
        return false
      }

      arguments[1] != undefined && _this.parse_arguments(data)
      _this.$mobilemenu.parse_mm(mmpanel)//_this.mm_close_button, _this.mm_back_button);
      _this.$mobilemenu.init_mm(mmpanel)
      _this.mm_enable_breakpoint && _this.$mobilemenu.check_resolution_mm(mmpanel)//_this.mm_breakpoint);
      $button.mm_handler(mmpanel)
    },
    parse_arguments: function(data){
      var _this = this
      if(Object(data).hasOwnProperty('menu_class')) _this.$mobilemenu = $('.'+data.menu_class)

      $.each(data, function( k, v ) {
        switch(k) {
        case 'right':
          v && _this.$mobilemenu.addClass('mm-right')
          break
        case 'close_button_name':
          _this.mm_close_button = v
          break
        case 'back_button_name':
          _this.mm_back_button = v
          break
        case 'width':
          _this.$mobilemenu.css('width', v)
          break
        case 'breakpoint':
          _this.mm_breakpoint = v
          break
        case 'enable_breakpoint':
          _this.mm_enable_breakpoint = v
          break
        case 'mobile_button':
          _this.mm_mobile_button = v
          break
        case 'remember_state':
          _this.remember_state = v
          break
        case 'second_button':
          _this.second_button = v
          break
        }
      })
    },
    show_button_in_mobile: function($button){
      var _this = this
      if(_this.mm_mobile_button) {
        window.innerWidth > _this.mm_breakpoint ? $button.hide() : $button.show()
        $(window).resize(function(){
          window.innerWidth > _this.mm_breakpoint ? $button.hide() : $button.show()
        })
      }
    }
  }
  mmpanel.init($(this), arguments[0])
  mmpanel.show_button_in_mobile($(this))
}

$.fn.check_resolution_mm = function(mmpanel) {
  var _this = $(this)
  $(window).resize(function(){
    if(!$('body').hasClass('mm-open') || !_this.hasClass('mmitemopen')) return false
    window.innerWidth > mmpanel.mm_breakpoint && _this.closemm(mmpanel)
  })
}
$.fn.mm_handler = function(mmpanel){
  $(this).click(function(e){
    e.preventDefault()
    mmpanel.$mobilemenu.openmm()
  })

  if(mmpanel.second_button != false){
    $(mmpanel.second_button).click(function(e){
      e.preventDefault()
      mmpanel.$mobilemenu.openmm()
    })
  }
}

$.fn.parse_mm = function(mmpanel) {
  var $mm_curent = $(this).clone(),
    $mm_new = $(get_mm_parent()),
    $mm_block = false,
    count = 0,
    _this = false,
    $btnBack = false,
    $ul
  $(this).empty()

  $mm_curent.find('a').each(function(){
    _this = $(this)
    $ul = _this.parent().find('ul').first()
    if($ul.length) {
      count++
      $ul.prepend('<li></li>').find('li').first().append(_this.clone().addClass('mm-original-link'))
      _this.attr('href', '#mm'+count).attr('data-target', '#mm'+count).addClass('mm-next-level')
    }
  })
  $mm_curent.find('ul').each(function(index){
    $btnBack = false
    $mm_block = $(get_mm_block()).attr('id', 'mm'+index).append($(this))
    if (index == 0) {
      $mm_block.addClass('mmopened').addClass('mmcurrent').removeClass('mmhidden')
      $btnBack = getButtonClose($mm_curent.find('.mm-closebtn').html(), mmpanel.mm_close_button)
      $mm_block.find('ul').first().prepend($btnBack)
    }
    else {
      $btnBack = getButtonBack($mm_curent.find('.mm-backbtn').html(), mmpanel.mm_back_button)
      $mm_block.find('ul').first().prepend($btnBack)
    }
    $mm_new.append($mm_block)
  })

  $(this).append($mm_new)
}
$.fn.init_mm = function(mmpanel) {
  var _parent = $(this)
  _parent.find('a').each(function(){
    $(this).click(function(e){
      var _this = $(this)
      var $panel = false
      var $currobj = false
      var lv = ''
      if(_this.hasClass('mm-next-level')){
        e.preventDefault()
        lv = _this.attr('href')
        $currobj = _parent.find('.mmcurrent')
        $currobj.addClass('mmsubopened').removeClass('mmcurrent')
        _parent.find(lv).removeClass('mmhidden')
        setTimeout(function(){_parent.find(lv).scrollTop(0).addClass('mmcurrent').addClass('mmopened')}, 0)
        setTimeout(function(){$currobj.addClass('mmhidden')}, delay_tab)
        return false
      }
      if(_this.hasClass('mm-prev-level')){
        e.preventDefault()
        lv = _this.attr('href')
        $currobj = _parent.find('.mmcurrent')
        $currobj.removeClass('mmcurrent').removeClass('mmopened')
        _parent.find('.mmsubopened').last().removeClass('mmhidden').scrollTop(0).removeClass('mmsubopened').addClass('mmcurrent')
        setTimeout(function(){$currobj.addClass('mmhidden')}, delay_tab)
        return false
      }
      if(_this.hasClass('mm-close')){
        _parent.closemm(mmpanel)
        return false
      }
    })
  })
  $('.mm-fullscreen-bg').click(function(e){
    e.preventDefault()
    _parent.closemm(mmpanel)
  })
}
$.fn.openmm = function(){
  var _this = $(this)
  _this.show()
  setTimeout(function(){$('body').addClass('mm-open');_this.addClass('mmitemopen');$('.mm-fullscreen-bg').fadeIn(delay_show_mm)}, 0)
}
$.fn.closemm = function(mmpanel){
  var _this = $(this)
  _this.addClass('mmhide')
  $('.mm-fullscreen-bg').fadeOut(delay_hide_mm)
  setTimeout(function(){ mm_destroy(_this, mmpanel) }, delay_hide_mm)
}
function mm_destroy(_parent, mmpanel){
  if(!mmpanel.remember_state) {
    _parent.find('.mmpanel').toggleClass('mmsubopened mmcurrent mmopened', false).addClass('mmhidden')
    _parent.find('#mm0').addClass('mmopened').addClass('mmcurrent').removeClass('mmhidden')
  }
  _parent.toggleClass('mmhide mmitemopen', false).hide()
  $('body').removeClass('mm-open')
}
function get_mm_parent(){
  return '<div class="mmpanels"></div>'
}
function get_mm_block(){
  return '<div class="mmpanel mmhidden">'
}
function getButtonBack(value, _default) {
  value = value == undefined ? _default : value
  return '<li><a href="#" data-target="#" class="mm-prev-level">'+value+'</a></li>'
}
function getButtonClose(value, _default) {
  value = value == undefined ? _default : value
  return '<li class="mm-close-parent"><a href="#close" data-target="#close" class="mm-close">'+value+'</a></li>'
}
function getFullscreenBg() {
  return '<div class="mm-fullscreen-bg"></div>'
}
