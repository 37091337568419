// Libs
import './lib/slick'
import './lib/panelmenu'
import './lib/perfect-scrollbar.min'

// Template
import './template'

// Modules
import './modules/sliders'
